@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */

@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1200px) {}


@media only screen and (max-width: 1199px) {

  /*Header*/
  .e-header-desktop {
    display: none;
  }

  .e-header-mobile {
    display: block;
  }

  /* 
  .e-play-btn1 {
    right: 83px;

    
  } */

  .e-header-login,
  .e-header-open-ac {
    width: 30%;
    margin-top: 20px;
  }

  .e-p-btn-res {
    padding: 15px 8px !important;
  }

  .e-filter-item-lang {
    margin-right: -18px;
  }

}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 992px) {

  /*Home*/
  .e-course-card {
    margin-bottom: 30px;
  }

  .e-category-section .mt-5 {
    margin: 0 !important;
  }

  .e-category-item {
    margin-top: 40px !important;
    z-index: 1;
    position: relative;
  }

  header .e-logo {
    width: 90px;
  }

  .e-home-banner,
  .e-pattern-3,
  .e-pattern-4 {
    display: none;
  }

  .e-cta-banner {
    height: 200px;
    position: absolute;
    bottom: -60px;
    right: -167px;
  }

  .e-cta-section {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  .e-cta-section .e-card-transition {
    width: 130px;
  }

  .e-search-submit {
    height: 51px;
    right: 0px;
  }

  /*Profile*/
  .e-profile-wrap .e-banner-section {
    padding: 140px 0 60px;
  }

  .e-profile-tab-item {
    font-size: 16px;
  }

  .e-orders-wrap,
  .e-notification-wrap {
    padding: 40px 0px;
  }

  .e-orders-wrap .text-right {
    text-align: left !important;
    padding-bottom: 20px;
  }

  /*All Course */
  .e-all-course-wrap .e-banner-section {
    padding: 140px 0 60px;
  }

  /* All course */
  .e-filter-item-sort {
    position: relative;
  }

  .e-filter-wrap {
    width: 400px;
  }

  .e-lang-filter-wrap {
    right: 30px;
    left: initial;
  }

  .e-sort-filter-wrap {
    right: 20px !important;

  }


  /*Course Detail*/
  .e-course-right-section {
    top: 0;
  }

  .e-enroll-widget {
    margin: 30px 0;
  }

  .e-app-store-banner-wrap {
    margin-bottom: 30px;
  }

  .e-enroll-widget img {
    left: 15px;
    bottom: 15px;
    top: initial;
  }

  /*Placeholder*/
  .e-card-placeholder img {
    width: 100%;
  }

  .e-card-placeholder span {
    bottom: 200px;
  }

  .e-profile-wrap .e-banner-section {
    padding: 140px 0 144px;
  }

  .e-wallet-wraper {
    left: 0px;
    top: 100px;
  }

  .e-filter-item-lang {
    margin-right: 0;
  }
}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {

  /* All course */
  .e-filter-item-sort {
    margin-top: 30px;
    position: relative;
  }

  .e-filter-wrap {
    width: 400px;
  }

  .e-lang-filter-wrap {
    right: 30px;
    left: initial;
  }

  .e-sort-filter-wrap {
    left: 20px !important;
    top: 80px;
  }

  /*Placeholder*/
  .e-card-placeholder span {
    bottom: 140px;
  }

  .e-play-btn1 {
    right: 45%;

  }

  .e-play-btn2 {
    right: 46%;
  }

  .e-banner-section.e-wallet-history-banner .container,
  .e-wallet-transactions .container {
    padding: 0px 0px;
  }

  .e-profile-state-sec {
    display: block;
  }

  /*herder*/

  .e-header-login,
  .e-header-open-ac {
    width: 70%;
  }
}

/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {
  h1 {
    line-height: 64px;
  }

  .e-footer .text-right {
    text-align: left !important;
  }

  .e-powered-by {
    padding-top: 20px !important;
  }

  /*Header"*/
  .e-header-login,
  .e-header-open-ac {
    width: 100%;
  }

  /*Profile*/
  .e-profile-tab-item {
    font-size: 14px;
    background-size: 20px;
    margin-right: 20px;
    padding-left: 25px;
  }

  /* All course */
  .e-filter-wrap {
    width: 360px;
  }

  .e-lang-filter-wrap {
    right: 15px;
    left: initial;
  }

  .e-sort-filter-wrap {
    left: 20px !important;
    top: 80px;
  }

  /* Tutor */
  .e-tutor-detail-wrap .e-course-wishlist {
    top: 15px;
    right: 15px;
  }

  /*Home*/
  .e-category-item {
    margin-top: 20px !important;
  }

  /*Placeholder*/
  .e-card-placeholder span {
    bottom: 130px;
  }

  /*Course detail*/
  .e-review-btn-wrap {
    text-align: left !important;
  }

  .e-play-btn1 {
    right: 177px;
  }

  .e-banner-section.e-wallet-history-banner .container,
  .e-wallet-transactions .container {
    padding: 0px 50px;
  }

  .e-transaction-desc {
    text-align: left;
  }

  .e-debit-amount,
  .e-credit-amount {
    text-align: left;
  }

  .e-profile-tab-item {
    padding-left: 20px;
  }

  .e-gst-wrap {
    font-size: 14px;
  }

  .e-cta-banner-d-none {
    display: none;
  }

  /* .e-course-tag-res {
    padding: 10px 40px!important;
    margin-right: 20px !important;
  } */
  .e-view-all {
    padding-right: 50px;

  }

  .e-p-btn-res {
    padding: 15px 2px !important;
  }

  .e-course-tab-item {
    margin-right: 20px;
    font-size: 16px;
  }

}

@media only screen and (max-width: 380px) {

  h1 {
    line-height: 52px;
  }

  /*Placeholder*/
  .e-card-placeholder span {
    bottom: 90px;
  }

  /* All course */
  .e-filter-wrap {
    width: 320px;
  }

  .e-play-btn1 {
    right: 138px;
  }

  /* .e-course-tag-res {
    padding: 10px 38px!important;
    margin-right:14px !important;
  } */

  .e-p-btn-res {
    padding: 15px 0 !important;
  }

}


/* ==============================
   Small Phone
   ============================== */
@media only screen and (max-width: 319px) {}

@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */

@media only screen and (max-width: 1366px) {}

@media only screen and (max-width: 1200px) {}


@media only screen and (max-width: 1199px) {

  /*Header*/
  .e-header-desktop {
    display: none;
  }

  .e-header-mobile {
    display: block;
  }

  .e-play-btn1 {
    right: 83px;

    /* opacity: 0.5; */
  }

  .e-header-login,
  .e-header-open-ac {
    width: 30%;
    margin-top: 20px;
  }
}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 992px) {

  /*Home*/
  .e-course-card {
    margin-bottom: 30px;
  }

  .e-category-section .mt-5 {
    margin: 0 !important;
  }

  .e-category-item {
    margin-top: 40px !important;
    z-index: 1;
    position: relative;
  }

  header .e-logo {
    width: 90px;
  }

  .e-home-banner,
  .e-pattern-3,
  .e-pattern-4 {
    display: none;
  }

  .e-cta-banners {
    display: none;
  }

  .e-cta-content-wrapper .e-p-btn {
    padding: 14px 0px;
  }

  .e-cta-content-wrapper .e-p-btn {
    width: 100%;
  }

  .e-cta-banner {
    height: 200px;
    position: absolute;
    bottom: -60px;
    right: -167px;
  }

  .e-cta-section {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  .e-cta-section .e-card-transition {
    width: 130px;
  }

  .e-search-submit {
    height: 47px;
    right: 0px;
    margin-right: 16px;
  }

  /*Profile*/
  .e-profile-wrap .e-banner-section {
    padding: 140px 0 60px;
  }

  .e-profile-tab-item {
    font-size: 16px;
  }

  .e-orders-wrap,
  .e-notification-wrap {
    padding: 40px 0px;
  }

  .e-orders-wrap .text-right {
    text-align: left !important;
    padding-bottom: 20px;
  }

  /*All Course */
  .e-all-course-wrap .e-banner-section {
    padding: 140px 0 60px;
  }

  /* All course */
  .e-filter-item-sort {
    position: relative;
  }

  .e-filter-wrap {
    width: 400px;
  }

  .e-lang-filter-wrap {
    right: 30px;
    left: initial;
  }

  .e-sort-filter-wrap {
    right: 20px !important;

  }


  /*Course Detail*/
  .e-course-right-section {
    top: 0;
  }

  .e-enroll-widget {
    margin: 30px 0;
  }

  .e-app-store-banner-wrap {
    margin-bottom: 30px;
  }

  .e-enroll-widget img {
    left: 15px;
    bottom: 15px;
    top: initial;
  }

  /*Placeholder*/
  .e-card-placeholder img {
    width: 100%;
  }

  .e-card-placeholder span {
    bottom: 200px;
  }

  .e-profile-wrap .e-banner-section {
    padding: 140px 0 144px;
  }

  .e-wallet-wraper {
    left: 0px;
    top: 100px;
  }

  /*home*/
  .e-mob-mask {
    height: 400px;
    position: relative;
    bottom: 0;
    right: -37px;
    top: 31%;
  }

  /*webinar-detail*/
  .e-position-wraper {
    right: 0;
    top: 200px;
  }
}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {

  /* All course */
  .e-filter-item-sort {
    margin-top: 30px;
    position: relative;
  }

  .e-filter-wrap {
    width: 400px;
  }

  .e-lang-filter-wrap {
    right: 30px;
    left: initial;
  }

  .e-sort-filter-wrap {
    left: 20px !important;
    top: 80px;
  }

  /*Placeholder*/
  .e-card-placeholder span {
    bottom: 140px;
  }

  .e-play-btn1 {
    right: 232px;

    /* opacity: 0.5; */
  }

  .e-banner-section.e-wallet-history-banner .container,
  .e-wallet-transactions .container {
    padding: 0px 0px;
  }

  .e-profile-state-sec {
    display: block;
  }

  /*herder*/

  .e-header-login,
  .e-header-open-ac {
    width: 70%;
  }

  /*home slider*/
  .e-home-banner,
  .e-home-banner-pattern3,
  .e-home-banner-pattern4 {
    display: none !important;
  }

  .e-mob-mask {
    display: none !important;
  }

  .e-pt-container {
    padding-top: 400px !important;
  }

  .e-position-wraper {
    /* right: 0; */
    top: 400px;
  }

  .e-webinar-search {
    float: left;
  }
  .e-web-search-submit{
    left: 258px;
  }

  .upcoming-search-icon{
    left: 226px;
    right: 0;
  }
  .upcoming-search-close{
    left: 226px;
    right: 0;
  }
  .carousel-control-next{
    right: 0;
  }
  .carousel-control-prev{
    left: 0;
  }
}

/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {
  h1 {
    font-size: 50px;
  }

  .e-footer .text-right {
    text-align: left !important;
  }

  .e-powered-by {
    padding-top: 20px !important;
  }

  /*Header"*/
  .e-header-login,
  .e-header-open-ac {
    width: 100%;
  }

  /*Profile*/
  .e-profile-tab-item {
    font-size: 14px;
    background-size: 20px;
    margin-right: 20px;
    padding-left: 25px;
  }

  /* All course */
  .e-filter-wrap {
    width: 360px;
  }

  .e-lang-filter-wrap {
    right: 15px;
    left: initial;
  }

  .e-sort-filter-wrap {
    left: 20px !important;
    top: 80px;
  }

  /* Tutor */
  .e-tutor-detail-wrap .e-course-wishlist {
    top: 15px;
    right: 15px;
  }

  /*Home*/
  .e-category-item {
    margin-top: 20px !important;
  }

  /*Placeholder*/
  .e-card-placeholder span {
    bottom: 130px;
  }

  /*Course detail*/
  .e-review-btn-wrap {
    text-align: left !important;
  }

  .e-play-btn1 {
    right: 177px;
  }

  .e-banner-section.e-wallet-history-banner .container,
  .e-wallet-transactions .container {
    padding: 0px 50px;
  }

  .e-transaction-desc {
    text-align: left;
  }

  .e-debit-amount,
  .e-credit-amount {
    text-align: left;
  }

  .e-profile-tab-item {
    padding-left: 20px;
  }

  .e-gst-wrap {
    font-size: 14px;
  }

  /*webinar detail*/
  .e-position-wraper {
    /* right: 0; */
    top: 400px;
  }

  .e-pt-container {
    padding-top: 500px !important;
  }
  .e-profile-name {
    font-size: 22px !important;
  }

}

@media only screen and (max-width: 380px) {

  /*Placeholder*/
  .e-card-placeholder span {
    bottom: 90px;
  }

  /* All course */
  .e-filter-wrap {
    width: 320px;
  }

  .e-play-btn1 {
    right: 138px;
  }

.e-available-wrap{
  width: 292px;
}
.e-mask-img {
  width: 304px;
  left: 0;
  top: 122px;

}
}

/* ==============================
   Small Phone
   ============================== */
@media only screen and (max-width: 319px) {}