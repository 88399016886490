@charset "utf-8";

/*------------------------------------------------------------------

1. Navigations

-------------------------------------------------------------------*/

/*---- Header ----*/

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: #ffff;
  padding: 20px 0;
  box-shadow: 0px 4px 10px rgba(174, 169, 169, 0.1);
}

header .e-categories {
  display: inline-block;
  padding-left: 8px;
  background-image: url("../images/icons/category.svg");
  background-size: 15px;
  background-position: 0 5px;
  background-repeat: no-repeat;
  font-weight: 500;
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
  transition: color .35s ease-in;
}

header .e-categories:hover,
header .e-categories.e-active {
  color: #065CAB;
  background-size: 20px;
  background-image: url("../images/icons/category-1.svg");
  background-position: 0;
}

header .e-search {
  background: #FFFFFF;
  border: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  width: 83%;
  display: inline-block;
  padding: 12px 15px 12px 15px;
  background-image: url("../images/icons/search.svg");
  background-size: 14px;
  background-position: 89%;
  background-repeat: no-repeat;
  font-weight: 500;
  font-size: 13px;
  background-color: #F1F1F1;

  color: #000000;
}

header .e-search::-webkit-input-placeholder,
.e-webinar-search::-webkit-input-placeholder {
  color: #B3B3B3;
  font-weight: 500;
  font-size: 12px;

}

header .e-p-btn,
header .e-s-btn {
  font-size: 12px;
  padding: 10px 18px;
}

header .e-logo {
  width: 100px;
}

.e-menu-icon {
  width: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.e-menu-icon.e-close {
  width: 16px;
  height: 16px;
}

.e-header-mobile {
  display: none;
  position: relative;
  z-index: 2;
}

.e-google-login-wrap,
.e-google-login-wrap button,
.e-google-login-wrap button div,
.e-google-login-wrap button span {
  width: 1px;
  height: 1px;
  opacity: 0;
  padding: 0 !important;
  margin: 0 !important;
}

.e-error {
  color: red;
}

.e-header-name {
  letter-spacing: 0.01em;
  color: #000000;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin: 0 5px 0 30px;
}

.e-header-avatar {
  background: linear-gradient(90deg, #46C212 -0.66%, #065CAB 100%), #065CAB;
  border: 2px solid #B2FA94;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  font-weight: 600;
  padding: 8px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.e-search-submit {
  width: 40px;
  height: 45px;
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 0;
  background-image: url("../images/icons/right-arrow-white-tick.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #155cab;
  border-radius: 0px 4px 4px 0;
  cursor: pointer;
}

.e-search-submit:hover {
  opacity: .7;
}

.e-header-categories-overlay,
.e-logged-dropdown-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: rgba(255, 255, 255, 0.8);
}

.e-header-categories-wrap,
.e-logged-dropdown-wrap {
  position: absolute;
  z-index: 2;
  background: #fff;
  padding: 15px 15px 0 15px;
  box-shadow: 0px 1px 17px 6px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  right: -100px;
  min-width: 200px;
}

.e-header-category,
.e-dropdown-item {
  padding-bottom: 18px;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #000000;
  cursor: pointer;
  text-align: left;
}

.e-header-category:hover,
.e-dropdown-item:hover {
  color: #065CAB;
}

.e-logged-dropdown-wrap {
  right: 120px;
  min-width: 160px;
}

.e-dropdown-item {
  background-image: url("../images/icons/dash-black.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: 0 0;
  padding-left: 25px;
  transition: color .35s ease-in;
}

.e-dropdown-item-courses {
  background-image: url("../images/icons/dash-black.svg");
}

.e-dropdown-item-courses:hover {
  background-image: url("../images/icons/dash-blue.svg");
}

.e-dropdown-item-order {
  background-image: url("../images/icons/order-black.svg");
}

.e-dropdown-item-order:hover {
  background-image: url("../images/icons/order-blue.svg");
}

.e-dropdown-item-notify {
  background-image: url("../images/icons/notification-black.svg");
}

.e-dropdown-item-notify:hover {
  background-image: url("../images/icons/notification-blue.svg");
}

.e-dropdown-item-logout {
  background-image: url("../images/icons/logout-black.svg");
}

.e-dropdown-item-logout:hover {
  background-image: url("../images/icons/logout-blue.svg");
}

.e-dropdown-item-search {
  background-image: url("../images/icons/search-black.svg");
}

.e-dropdown-item-search:hover {
  background-image: url("../images/icons/search-blue.svg");
}

.e-dropdown-item-allCourse {
  background-image: url("../images/icons/filter.svg");
}

.e-dropdown-item-allCourse:hover {
  background-image: url("../images/icons/filter-blue.svg");
}

.e-mob-menu-wrap {
  background: #fff;
  padding: 30px;
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(196, 191, 191, 0.25);
}

.e-header-mobile .e-dropdown-item {
  padding-bottom: 25px;
}


/*---- Footer ----*/
.e-footer {
  background: #1E1E1E;
  padding: 50px 0;
}

.e-footer-copyright,
.e-footer-copyright a {
  color: #FFFFFF;
  letter-spacing: 0.01em;
  font-size: 12px;
  line-height: 15px;
  opacity: .7;
}

.e-footer .e-logo {
  max-width: 90px;
  margin-bottom: 20px;
}

.e-footer .e-powered-by {
  color: #FFFFFF;
  letter-spacing: 0.01em;
  font-size: 12px;
  line-height: 15px;
  padding-top: 54px;
  opacity: .8;
}

.e-footer .e-powered-by a {
  color: #FFFFFF;
  font-weight: 500;
}

.e-footer-contact {
  color: #ababab;
  font-size: 13px;
  padding-bottom: 10px;
  font-weight: 200;
}

.e-footer-contact a {
  color: #fff;
  font-weight: 400;
}

/*------------------------------------------------------------------

2. Home

-------------------------------------------------------------------*/
.e-banner-section {
  background: linear-gradient(90deg, #46C212 -0.66%, #065CAB 100%), #065CAB;
  padding: 160px 0 194px;
  height: 680px !important;
}

.e-banner-section-course {
  background: linear-gradient(90deg, #46C212 -0.66%, #065CAB 100%), #065CAB;
  padding: 160px 0 194px;
  height: 555px !important;
}

.e-banner-section-webinar {
  background: linear-gradient(98.68deg, #065CAB 63.44%, #46C212 109.35%) !important;
  padding: 160px 0 194px;
}

.e-banner-section3 {
  background: linear-gradient(83.46deg, #065CAB 4.38%, #46C212 107.69%) !important;
  padding: 160px 0 194px;

}

.e-banner-slider {
  /* height: 680px !important; */
}

.e-home-slider .slick-dots {
  bottom: 54px !important;
}

.e-home-slider .slick-dots li.slick-active button:before {
  color: transparent;
  background-color: #fff !important;
  width: 24px !important;
}

.e-home-slider .slick-dots li button:before {
  background-color: transparent !important;
  opacity: 10;
  border: 1px solid #fff;
  height: 8px;
  border-radius: 23px;
  width: 11px !important;
  color: transparent;
}

.e-home h1 {
  color: #fff;
}

.e-home h2 {
  color: #fff;
}

.e-home-banner {
  position: absolute;
  right: 0;
  top: 0;

  -webkit-animation: eHomeBanner 1.4s forwards;
  animation: eHomeBanner .8s forwards;
}

@keyframes eHomeBanner {
  0% {
    opacity: 0;
    top: -500px;
    right: -200px;
  }

  100% {
    opacity: 1;
    top: 0;
    right: 0;
  }
}

.e-home-banner-pattern3 {

  top: 100px;
  z-index: 0;
  position: absolute;
  right: 0;

}

.e-home-banner-pattern4 {
  top: 80px;
  z-index: 0;
  position: absolute;
  right: 0;
}

.e-banner-pattern-1 {
  position: absolute;
  top: 100px;
  z-index: 0;
}

.e-banner-pattern-2 {
  position: absolute;
  right: 520px;
  bottom: -50px;
}

.e-banner-pattern-3 {
  position: absolute;
  right: 30px;
  bottom: 0;
}

.e-banner-section .e-login-with-gmail,
.e-offer-modal .e-login-with-gmail {
  padding-right: 70px;
  box-shadow: 4px 4px 20px rgba(6, 92, 171, 0.2);
  z-index: 1;
  position: relative;
}

.e-login-with-gmail1 {
  color: #065CAB !important;
  background: #FFFFFF !important;
  box-shadow: 4px 4px 20px rgba(6, 92, 171, 0.2) !important;
  z-index: 1;
  position: relative;
  padding-right: 70px;
}

.e-login-with-gmail1:after {
  content: " ";
  background-image: url("../images/icons/right-arrow-blue.svg") !important;
  width: 26px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 25px;
  top: 24px;
}

.e-login-with-gmail:after {
  content: " ";
  background-image: url("../images/icons/right-arrow-white.svg");
  width: 26px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 25px;
  top: 24px;
}

.e-login-p {
  font-weight: 500;
}

.e-login-head {
  margin-top: -35px;
}

.e-home a:hover {
  text-decoration: none !important;
}

.e-veiw-all-btn {
  padding: 8px 24px;
}


/* Course categories section */
.e-cta-section {
  padding-bottom: 0;
}

.e-category-section {
  background: #065CAB;
  min-height: 400px;
}

.e-category-item {
  border: 2px solid #f1f1f1;
  border-radius: 30px;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding: 15px;
  cursor: pointer;
  background-image: linear-gradient(to left, transparent, transparent 50%, #FFFFFF 50%, #FFFFFF);
  background-position: 100% 0;
  background-size: 201% 100%;
  transition: all .35s ease-in;
  margin-bottom: 35px;
}

.e-category-item:hover {
  background-position: 0 0;
  color: #065CAB;
}

.e-category-section .e-pattern-1 {
  position: absolute;
  top: 20px;
}

.e-category-section .e-pattern-2 {
  position: absolute;
  right: 0;
  bottom: -80px;
  opacity: .55;
}

.e-head-secondary {
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 5px;
}

/*Webinar section*/
.e-webinar-card .e-webinar-date-and-time {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.67);
}

.e-webinar-date-and-time.e-webinar-date {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 18px;
}

.e-webinar-card .e-s-btn {
  padding: 8px 0;
  font-size: 13px;
}

/*Join platform*/
.e-join-platform p {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  opacity: 0.83;
}

.e-availble-on {
  font-size: 16px !important;
}

/* CTA */
.e-cta-section {
  background: #000000;
}

.e-cta-section p {
  opacity: 0.67;
  color: #FFFFFF;
  letter-spacing: 0.01em;
  font-size: 16px;
  line-height: 28px;
}

.e-cta-section .e-pattern-1 {
  position: absolute;
  top: 0;
  left: 0;
}

.e-cta-section .e-pattern-2 {
  position: absolute;
  top: 0;
  left: 0;
}

.e-cta-section .e-pattern-3 {
  position: absolute;
  top: 0;
  right: 0;
}

.e-cta-section .e-pattern-4 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.e-cta-banner {
  position: relative;
  z-index: 1;
}

/*New cta*/
.e-cta-sections {
  background: #E0F0FF;
}

.e-cta-content-wrapper {
  padding: 108px 0 80px;
}

.e-cta-content-wrapper h5,
.e-cta-content-wrapper p {
  font-weight: 400 !important;
  font-size: 48px;
  letter-spacing: 0.01em;
  color: #000000 !important;

}

.e-cta-content-wrapper p {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
  opacity: 0.83;
}

.e-cta-content-wrapper .e-p-btn {
  padding: 14px 24px;
}

.e-cta-content-wrapper input~label {
  display: none;
}

.e-cta-content-wrapper input {
  border: 1px solid transparent;
  box-shadow: 4px 4px 20px rgba(6, 92, 171, 0.2);
}

.e-cta-content-wrapper input:focus {
  border: 1px solid #065CAB !important;
}



/*------------------------------------------------------------------

3. Card

-------------------------------------------------------------------*/
.e-course-card {
  border: none;
  box-shadow: 0px 4px 10px rgba(196, 191, 191, 0.25);
}

.e-course-card .e-course-cover {
  height: 130px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.e-course-card h5 {
  opacity: 0.87;
  color: #000000;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
}

.e-course-card .card-body {
  padding: 15px;
}

.e-course-card .e-course-tag {
  color: #065CAB;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 30px;
}

.e-web-tag {
  color: #065CAB;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 18px 0 12px !important;

}

.e-course-card .e-course-tag span b {
  display: inline-block;
  margin: 0 5px;
}

.e-course-card .e-course-tag span:last-child b {
  display: none;
}

.e-course-card p {
  height: 14px;
}

.e-course-card .e-review {
  background-image: url("../images/icons/rating.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 14px;
  padding-left: 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
}

.e-price-regular {
  color: #AEAEAE;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: line-through;
  margin-right: 15px;
  font-weight: 500;
}

.e-price-offer {
  color: #46C212;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}

.e-course-author {
  background: #000000;
  border-radius: 0px 4px 0px 0px;
  display: inline-block;
  position: absolute;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 8px 16px;
  bottom: 0;
  left: 0;
}

.e-course-offer {
  background: #FF0303;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  color: #fff;
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
  padding: 3px 10px;
  left: 15px;
  top: 15px;
}

.e-course-wishlist {
  background-image: url("../images/icons/bookmark-lined-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  background-color: #000000;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  border-radius: 0px 0px 0px 3px;
  cursor: pointer;
  transition: all .4s ease;
}

.e-course-wishlist:hover {
  transform: translateY(-5px);
}

.e-course-wishlist.e-bookmarked {
  background-image: url("../images/icons/bookmark-filled-white.svg");
}





/*------------------------------------------------------------------

4. All Course

-------------------------------------------------------------------*/
.e-all-course-wrap h3 {
  color: #FFFFFF;
  position: relative;
  z-index: 1;
}

.e-all-course-wrap .e-banner-section {
  padding: 160px 0 60px;
}

.e-all-course-wrap .e-banner-pattern-1 {
  left: -60px;
  top: -60px;
}

.e-all-course-wrap .e-banner-pattern-2 {
  right: 9px;
  top: -40px;
}

.e-filter-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 15px;
  color: #000;
  font-weight: 600;

  background-image: url("../images/icons/filter.svg");
  background-position: 90%;
  background-repeat: no-repeat;
  background-size: 16px;
  cursor: pointer;
}

.e-filter-item-lang {
  background-image: url("../images/icons/lang-filter.svg");
  background-position: 90%;
  background-repeat: no-repeat;
  background-size: 16px;
}

.e-filter-item-sort {
  background-image: url("../images/icons/sort.svg");
  background-position: 90%;
  background-repeat: no-repeat;
  background-size: 16px;
}

.e-filter-overlay {
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.e-filter-wrap {
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  z-index: 99;
  width: 800px;
  top: 47px;
  left: 20px;
}

.e-filter-wrap {
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  font-weight: 400;
}

.e-filter-wrap .e-p-btn {
  padding: 12px 24px;
  font-size: 14px;
}

.e-lang-filter-wrap {
  width: 400px;
}

.e-filter-item span {
  margin-left: 5px;
  font-size: 11px;
  color: #fff;
  background-color: #46C212;
  text-align: center;
  display: inline-block;
  width: 20px;
  padding: 2px 0;
  border-radius: 100%;
}

.e-filter-item-sort span,
.e-green-dot {
  background-color: #46C212;
  width: 6px !important;
  height: 6px !important;
  display: inline-block;
  top: 17px;
  position: absolute;
}

.e-serach-results {
  min-height: 50vh;
}

.e-videos-count-tag {
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 11px;
  padding: 0px 7px;
  background: #f1f1f1;
}

/*------------------------------------------------------------------

5. Profile

-------------------------------------------------------------------*/
.e-profile-pic-placeholder {
  /*background: linear-gradient(90deg, #46C212 -0.66%, #065CAB 100%), #065CAB;*/
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  display: inline-block;
  padding: 20px;
  border-radius: 100%;
  letter-spacing: 0.01em;
  color: #46C212;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
}

img.e-profile-pic-placeholder {
  width: 40px;
  height: 40px;
  padding: 0px;
}

.e-profile-details-wrap h5 {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
}

.e-profile-details-wrap p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}

.e-wallet-wraper {
  display: inline-block;
  position: absolute;
  width: 255px;
  height: 100px;
  left: 438px;
  background-color: rgba(255, 255, 255, 0.1);
  /*opacity: 0.1;*/
  border-radius: 4px;
  bottom: 0;
  padding: 15px;
  transition: ease-in-out 0.5s;
}

.e-wallet-wraper:hover {
  transform: translateY(-5px);
}

.e-profile-state-sec {
  display: flex;
  justify-content: space-between;
}

.e-wallet-wraper h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  background-image: url("../images/profile/wallet.svg");
  background-repeat: no-repeat;
}

.e-wallet-wraper p {
  font-weight: 700;
  font-size: 24px;
  text-align: right;
}

.e-profile-wrap .e-banner-pattern-1 {
  left: -60px;
  top: -60px;
}

.e-profile-wrap .e-banner-section {
  /* padding: 151px 0 31px; */
  padding: 116px 0 31px;
}

.e-profile-tab-item {
  opacity: 0.3;
  color: #000000;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin-right: 30px;
  padding-left: 30px;
  padding-bottom: 5px;
  display: inline-block;
  cursor: pointer;
}

.e-profile-web-sec {
  padding: 20px 0 80px !important;
}

.e-profile-tab-courses {
  background-image: url("../images/profile/tab-course.svg");
  background-repeat: no-repeat;
}

.e-profile-tab-history {
  background-image: url("../images/profile/tab-history.svg");
  background-repeat: no-repeat;
}

.e-profile-tab-webinar {
  background-image: url("../images/profile/tab-webinar.svg");
  background-repeat: no-repeat;
}

.e-profile-tab-notify {
  background-image: url("../images/profile/tab-notify.svg");
  background-repeat: no-repeat;
}

.e-profile-tab-item.e-active {
  opacity: 1;
  border-bottom: 4px solid #46C212;
}

.e-profile-tab-item:hover {
  opacity: .7;
}

.e-profile-open-course-wrap {
  padding: 100px 0;
  background: linear-gradient(180deg, #F7F8FC 0%, rgba(255, 255, 255, 0) 55.73%), linear-gradient(90deg, #DFF0FF -0.66%, #B7FF99 100%);
}

.e-profile-tab-wrap {
  background: #F7F8FC;
  padding: 20px 0 15px;
}

.e-notify-wrap {
  background: #f9f9f9;
}

.e-notify-item {
  padding: 20px;
  border-bottom: 1px solid #efefef;
  font-weight: 400;
  color: #000;
}

.e-notify-item.e-active {
  background: #F1F8FF;
  color: #000000;
  font-weight: 500;
}

.e-phone,
.e-mail {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  opacity: 0.67;
  display: inline-block;
  padding-left: 25px;
  background-position: 0;
  margin-top: 5px;
  cursor: pointer;
  transition: all .35s ease-in;
  background-size: 18px;
  background-repeat: no-repeat;
}

.e-phone {
  background-image: url("../images/icons/phone-green.svg");
  margin-right: 30px;
}

.e-mail {
  background-image: url("../images/icons/message-green.svg");
}

.e-phone:hover,
.e-mail:hover {
  color: #065CAB;
}

.e-phone:hover {
  background-image: url("../images/icons/phone-blue.svg");
}

.e-mail:hover {
  background-image: url("../images/icons/message-blue.svg");
}

.e-order-item {
  background: #F7F8FC;
  border-radius: 12px;
}

.e-order-details {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(196, 191, 191, 0.25);
  border-radius: 10px;
  padding: 15px;
}

.e-order-details img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 5px;
}

.e-order-details h5 {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}

.e-order-details p {
  color: #888585;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 8px;
}

.e-order-details .e-review {
  background-image: url("../images/icons/star.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 14px;
  padding-left: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.e-invoice-detail-wrap {
  padding: 15px;
}

.e-invoice-detail-wrap h6 {
  color: #000000;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  padding-bottom: 10px;
}

.e-invoice-total-wrap {
  border-top: 1px dashed #CCCCCC;
  padding-top: 10px;
}

.e-invoice-detail-wrap .e-invoice-detail-item {
  display: inline-block;
  color: #000000;
  padding-bottom: 10px;
  opacity: 0.67;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}

.e-orders-wrap,
.e-notification-wrap {
  padding: 40px 0 80px;
}

.e-orders-wrap .e-s-btn {
  padding: 12px 20px;
}

.e-gst-wrap {
  color: #fff;
  font-size: 14px;
}

.e-profile-edit {
  /* background-image: url("../images/icons/edit-black.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px; */
  /* display: inline-block;
  position: absolute;
  top: 0;
  right: 0; */
  display: inline-flex;
  cursor: pointer;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  color: #000000;
  font-weight: 700;
  font-size: 13px;
}

/* .e-profile-edit:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
} */

/*------------------------------------------------------------------

6. Tutor

-------------------------------------------------------------------*/
.e-tutor-wrap .e-banner-section {
  background: linear-gradient(90deg, #6359FF 0%, #ACCBFF 100%), #065CAB;
}

.e-tutor-wrap .e-banner-section {
  padding: 140px 0 60px;
}

.e-tutor-wrap .e-profile-pic-placeholder {
  background: linear-gradient(90deg, #6359FF 0%, #ACCBFF 100%), #065CAB;
}

.e-tutor-detail-wrap .e-course-wishlist {
  background-color: transparent;
  background-size: contain;
  top: 0;
}

.e-designation {
  opacity: .8;
}

/*------------------------------------------------------------------

7. Course detail

-------------------------------------------------------------------*/
.e-course-detail-wrap h1 {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
}

.e-course-detail-wrap h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
}

.e-course-detail-wrap h2 span b {
  display: inline-block;
  margin: 0 5px;
}

.e-course-detail-wrap h2 span:last-child b {
  display: none;
}

.e-course-detail-wrap .e-banner-section .e-review {
  background-image: url("../images/icons/star-white.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 22px;
  padding-left: 25px;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: #fff;
  display: inline-block;
}

.e-course-detail-wrap .e-course-tag {
  border: 2px solid #fff;
  border-radius: 30px;
  padding: 10px 45px;
  display: inline-block;
  margin-right: 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  color: #fff;
}

.e-course-detail-wrap .e-banner-section .e-course-wishlist {
  background-color: transparent;
  background-size: contain;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
  top: 8px;
}

.e-course-detail-wrap .e-author-detail h5 {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.e-course-detail-wrap .e-author-detail p {
  color: #FFFFFF;
  opacity: 0.8;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.e-course-detail-wrap .e-author-detail img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 5px;
}

.e-author-detail {
  margin-top: 30px;
}

.e-author-detail:hover h5 {
  text-decoration: underline;
}

.e-author-detail.e-card-transition {
  box-shadow: none !important;
}

.e-tab-title-wrap {
  background: #F7F8FC;
  padding: 20px 0 28px;
}

.e-course-tab-item {
  opacity: 0.3;
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-right: 30px;
  cursor: pointer;
}

.e-course-tab-item:hover {
  opacity: .7;
}

.e-course-tab-item.e-active {
  opacity: 1;
  padding-bottom: 5px;
  border-bottom: 4px solid #46C212;
}

.e-modules-wrap .e-module-item-wrap h6 {
  color: #000000;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-top: 30px;
}

.e-modules-wrap .e-module-item {
  padding: 15px;
  box-shadow: 4px 4px 10px rgba(196, 191, 191, 0.25);
  border-radius: 10px;
}

.e-descp-wrap div,
.e-descp-wrap p,
.e-descp-wrap li,
.e-descp-wrap span {
  line-height: 200%;
  font-weight: 400;
  opacity: .9;
  font-size: 15px;
  white-space: pre-wrap;
  font-family: 'Quicksand', sans-serif !important;
}

.e-module-item p {
  opacity: 0.67;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000;
  margin-bottom: 0;
}

.e-module-item h5 {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}

.e-module-item img {
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 5px;
}

.e-video-preview-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #00000059;
  z-index: 2;
  display: inline-block;
  position: absolute;
  border-radius: 5px;
  background-image: url("../images/icons/play-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}

.e-module-item {
  margin-bottom: 15px;
}

.e-free-tag {
  color: #46C212;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  position: absolute;
  right: 0px;
  bottom: -10px;
}

.e-free-course-tag span {
  text-align: center;
  color: #46C212;
  font-size: 16px;
  font-weight: 600;
  background-color: #edf9e8;
  padding: 10px 30px;
  border-radius: 30px;
  display: inline-block;
}

.e-lock-tag {
  width: 16px;
  height: 16px;
  background-image: url("../images/icons/lock.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0px;
  bottom: -10px;
}

.e-course-brief p,
.e-course-brief ul li {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-weight: 300;
  opacity: 0.67;
}

.e-course-brief ul li {
  padding-left: 0px;
  margin-bottom: 15px;
}

.e-course-review .e-review-count {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: #fff;
  background: #262626;
  border-radius: 30px;
  padding: 5px 15px 5px 40px;
  background-image: url("../images/icons/star-white.svg");
  background-position: 10px;
  background-repeat: no-repeat;
  background-size: 22px;
  display: inline-block;
}

.e-course-review-item img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100%;
}

.e-course-review-item h5 {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-top: 10px;
}

.e-course-review-item h5 b {
  color: #46C212;
  font-weight: 600;
}

.e-course-review-item p {
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  opacity: 0.67;
}

.e-course-review-item {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 30px;
}


.e-course-review .col-lg-6:nth-last-child(1) .e-course-review-item,
.e-course-review .col-lg-6:nth-last-child(2) .e-course-review-item {
  border-bottom: none;
}


.e-enroll-widget {
  padding: 0px 30px 30px;
  background: #fff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.e-enroll-banner-wrap {
  border: 5px solid #fff;
  border-radius: 5px;
  top: -30px;
  position: relative;
}

.e-enroll-widget img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
}

.e-enroll-btn {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #FFFFFF;
  width: 100%;
  text-align: center;
  background: #46C212;
  border: 1px solid #46C212;
  background-image: linear-gradient(to left, transparent, transparent 50%, #000 50%, #000);
  background-position: 100% 0;
  background-size: 201% 100%;
  padding: 14px 28px;
}

.e-enroll-btn b {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  text-transform: capitalize;
  color: #FFFFFF;
}

.e-enroll-btn span {
  display: inline-block;
  position: relative;
  top: -5px;
}

.e-wallet {
  background-image: url('../images/course-detail/icon_wallet.png');
  background-repeat: no-repeat;
  background-position: 95% 50% !important;
  background-size: 30px;
}

.e-wallet:hover {
  background-size: 35px !important;
}

.e-buy-course-wrap .e-success button {
  position: relative !important;
  margin-right: -40px;
}

.e-enroll-widget p {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0;
}

.e-app-store-banner-wrap {
  background: #065CAB;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 30px;
}

.e-app-store-banner-wrap .e-pattern-1,
.e-app-store-banner-wrap .e-pattern-2 {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
}

.e-app-store-banner-wrap .e-pattern-2 {
  left: 30px;
}

.e-app-store-banner-wrap h5 {
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  position: relative;
  z-index: 1;
}

.e-app-store-banner-wrap p {
  letter-spacing: 0.01em;
  color: #FFFFFF;
  opacity: 0.67;
  font-weight: 400;
  font-size: 11px;
  line-height: 28px;
}

.e-store-link {
  max-width: 110px;
  margin-right: 30px;
  opacity: .9;
  cursor: pointer;
}

.e-store-poster-banner {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 160px;
}

.e-course-right-section {
  position: relative;
  top: -200px;
  transition: top 1s ease-in;
}

.e-course-right-section.e-player-active {
  top: 0;
}

.e-enroll-banner-wrap .e-discount-tag {
  background: #FF0303;
  border-radius: 30px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 20px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.e-play-btn {
  width: 60px !important;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(90deg, rgba(70, 194, 18, 0.5) -0.66%, rgba(6, 92, 171, 0.5) 100%), #66E431;
  background-image: url("../images/icons/play-white-2.svg");
  background-position: 22px;
  background-repeat: no-repeat;
  background-size: 22px;
  display: inline-block;
  position: absolute;
  top: 80px;
  right: 150px;
}

.e-enrolled-widget .e-author-detail h5 {
  margin-bottom: 0;
}

.e-enrolled-widget .e-author-detail h5,
.e-enrolled-widget p {
  color: #000 !important;
  text-align: left;
}

.e-enrolled-widget .e-author-detail p {
  margin-top: 10px;
}

.e-enrolled-widget h4 {
  font-size: 24px;
  padding-top: 30px;
}

.e-enrolled-widget .e-tag-wrap {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-top: 20px;
}

.e-enrolled-widget .e-tag-wrap span b {
  display: inline-block;
  margin: 0 5px;
  font-weight: 300;
}

.e-enrolled-widget .e-tag-wrap span:last-child b {
  display: none;
}

.e-enrolled-widget .e-course-tag {
  border: 1px solid #4a4a4a;
  border-radius: 30px;
  padding: 5px 15px;
  display: inline-block;
  margin-right: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4e4e4e;
}

.e-enrolled-widget .e-course-wishlist {
  position: relative;
  background-image: url("../images/icons/bookmark-lined-black.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  background-color: snow;
  width: 18px;
  height: 18px;
  top: 4px;
}

.e-enrolled-widget .e-course-wishlist.e-bookmarked {
  background-image: url("../images/icons/bookmark-filled-black.svg");
}

.e-active-video {
  background-image: url("../images/icons/video-progress.svg");
  background-position: 97% 80%;
  background-repeat: no-repeat;
  background-size: 28px;
  background-color: #F7F8FC;
}

/*.e-active-video.e-viewed-video{
  background-color: #F7F8FC;
  background-image: none;
}*/
.e-viewed-video {}

.e-completed-video {
  opacity: .7;
  background-color: #f1f1f1;
  background-image: url("../images/icons/tick-green.svg");
  background-size: 18px;
  box-shadow: 4px 4px 10px rgba(121, 121, 121, 0.25) !important;
  background-repeat: no-repeat;
  background-position: 97% center;
}

.e-no-review-wrap h6 {
  font-size: 16px;
  font-weight: 600;
}

.e-no-review-wrap .e-p-btn,
.e-add-review-btn {
  padding: 10px 18px;
  font-size: 14px;
}

.e-gst-disclaimer {
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #616161;
}

.e-video-dummy {
  height: 1px;
  width: 1px;
  opacity: 0;
}




/*------------------------------------------------------------------

8. Modals

-------------------------------------------------------------------*/
.modal h5 {
  color: #000000;
  opacity: 0.8;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
}

.modal h6 {
  color: #000000;
  opacity: 0.9;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.e-partner-login{
  border-top: 1px solid #000000;
}

.modal-header {
  border-bottom: 0;
}

.modal .close {
  font-size: 30px;
  font-weight: 300;
  line-height: .6;
  opacity: .7;
  z-index: 5;
}

.modal-dialog {
  max-width: 560px;
}

.modal-content {
  padding: 15px;
}

.modal-footer {
  border-top: 0;
}

.e-form-modal .e-p-btn {
  box-shadow: 4px 4px 20px rgba(6, 92, 171, 0.2);
  z-index: 1;
  position: relative;
  padding: 15px 70px 18px 30px;
}

.e-form-modal .e-p-btn:after {
  content: " ";
  /* background-image: url(/static/media/right-arrow-white.95d16a61.svg); */
  width: 28px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 25px;
  top: 22px;
}

.e-buy-course-wrap p {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  opacity: 0.6;
  padding: 0 80px;
}

.e-buy-course-wrap h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  margin-top: 30px;
}

.e-buy-course-wrap .e-p-btn {
  background: #46C212;
  background-image: linear-gradient(to left, transparent, transparent 50%, #000 50%, #000);
  background-position: 100% 0;
  background-size: 201% 100%;
  border: 1px solid #46C212;
}

.e-buy-course-wrap .e-p-btn:hover {
  background-position: 0 0;
  color: #fff;
  border: 1px solid #000;
}

.e-form-modal .css-yk16xz-control {
  padding: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: none !important;
}

.e-open-account-modal .css-1pahdxg-control {
  padding: 5px !important;
}

.e-open-account-modal .css-1pahdxg-control input {
  text-align: center !important;
}

.e-form-modal .css-26l3qy-menu {
  position: relative;
  z-index: 2;
}

.e-open-account-modal .css-1uccc91-singleValue {
  font-weight: 400;
  color: #000;
}

.css-1pahdxg-control {
  box-shadow: none !important;
  border: 1px solid #065CAB !important;
  border-radius: 5px !important;
}

.e-edit-select .css-1pahdxg-control {
  border-radius: 4px !important;
}

.e-secondary-head b {
  font-size: 15px;
  opacity: 0.67;
  font-weight: 400;
}

.e-secondary-head {
  line-height: 0px !important;
}

.e-open-account-modal h5 {
  line-height: 25px !important;
}

.modal .e-success {
  text-align: center;
}

.modal .e-success .modal-body {
  padding: 30px 0;
}

.modal .e-success h6 {
  font-weight: 600;
}

.modal .e-success p {
  padding: 0 60px;
  margin-bottom: 0;
  font-weight: 400;
}

.modal .e-success img {
  max-width: 200px;
}

.modal .e-success button {
  position: absolute;
  right: 30px;
  z-index: 9;
}

.modal .e-success .modal-header {
  padding: 0;
  margin: 0;
}


.e-modal-wrap .e-rate-modal {
  max-width: 600px;
}

.e-modal-wrap textarea::placeholder {
  color: #d7d7d7;
  font-size: 14px;
  font-weight: 500;
}

.e-modal-wrap .e-rate-star {
  display: inline-block;
  background-image: url("../images/icons/star-grey.svg");
  width: 24px;
  height: 24px;
  background-size: cover;
  top: 1px;
  position: relative;
  margin-right: 15px;
  cursor: pointer;
  float: right;
}

.e-rate-star-wrap {
  display: inline-block !important;
}

.e-modal-wrap .e-rate-star:hover,
.e-modal-wrap .e-rate-star.e-active {
  background-image: url("../images/icons/star-green.svg");
}

.e-modal-wrap .e-rate-star:hover,
.e-modal-wrap .e-rate-star:hover~.e-rate-star {
  background-image: url("../images/icons/star-green.svg");
}

.e-search-modal {
  /*position: relative;
  z-index: 9;*/
}

/**Webinar laguage modal*/
.e-webinar-language span.checkmark {
  right: 0 !important;
  position: absolute;
  top: 0;
  left: inherit;
  height: 16px;
  width: 16px;
  border: 1px solid #46C212;
}

.e-webinar-language .e-checkbox-wrap {
  display: block;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.01em;
  line-height: 16px;
}

.e-webinar-language input[type=checkbox] {
  visibility: hidden;
}


.e-webinar-language .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
}

.e-webinar-language .e-checkbox-wrap input:checked~.checkmark {
  background-color: #fff;
}

.e-webinar-language .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.e-webinar-language .e-checkbox-wrap input:checked~.checkmark:after {
  display: block;
}

.e-webinar-language .e-checkbox-wrap .checkmark:after {
  left: 3px;
  top: 3px;
  width: 5px;
  height: 5px;
  border: solid #46C212;
  transform: rotate(90deg);
  border-width: 4px 4px 4px 4px;
}

.e-language-modal .e-p-btn {
  border: none;
  padding: 14px !important;
}

/*------------------------------------------------------------------

9. Placeholders

-------------------------------------------------------------------*/
.e-card-placeholder img {
  filter: grayscale(100%);
  filter: grayscale(100%) blur(.6px);
  opacity: .7;
  box-shadow: 0px 4px 10px rgba(196, 191, 191, 0.25);
}

.e-card-placeholder span {
  height: 4px;
  width: 100%;
  display: inline-block;
  position: absolute;
  margin-left: 0px;
  bottom: 153px;
  background-color: #ccc;
  left: 0;
}

.e-card-placeholder span:before {
  width: 20px;
  height: 4px;
  background-color: #065CAB;
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;

  -webkit-animation: placeholderLeftToRigth 1.8s ease infinite;
  animation: placeholderLeftToRigth 1.8s ease infinite;
}

@keyframes placeholderLeftToRigth {
  0% {
    opacity: .6;
    left: 0;
  }

  50% {
    opacity: 1;

  }

  100% {
    opacity: .0;
    left: 92%;
  }
}

.e-order-placeholder img {
  box-shadow: none;
  background: transparent;
}

.e-order-placeholder span {
  top: 125px;
  bottom: initial;
  left: 10px;
}

.e-banner-section.e-placeholder {
  height: 500px;
  background: #ccc;
}

.e-tab-title-wrap.e-placeholder {
  height: 80px;
}

.e-modules-wrap.e-placeholder {
  height: 700px;
}

.e-course-detail-wrap .e-order-placeholder span {
  top: 0px;
  left: 0;
}

.e-course-detail-wrap .e-order-placeholder span:before {
  width: 40px;
  -webkit-animation: placeholderLeftToRigth 2.2s ease infinite;
  animation: placeholderLeftToRigth 2.2s ease infinite;
}

/*------------------------------------------------------------------

10. Video Player

-------------------------------------------------------------------*/
.react-player {
  background-color: #000;
}

.e-video-controls-wrap {
  position: absolute;
  bottom: 100px;
  z-index: 99;
}

.e-video-laoder {
  padding: 22.5% 0;
  background-color: #000;
}

.e-video-laoder div {
  background-image: url("../images/loader/loader-thin.gif");
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 60px;
  text-align: center;
  color: #fff;
}

.vjs_video_3-dimensions.vjs-fluid {
  padding-top: 45%;
  height: 100%;
  width: initial;
  margin-top: 84px;
  display: inline-block;
}

.react-awesome-player-container {
  text-align: center;
}

/*------------------------------------------------------------------

11. Slider

-------------------------------------------------------------------*/
.e-slider-item {
  padding: 15px 30px 15px 0;
}

.slick-prev,
.slick-next {
  background-image: url("../images/icons/left-arrow-black.svg") !important;
  background-size: 24px !important;
  width: 26px !important;
  height: 10px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: absolute !important;
  top: -15px !important;
  right: 70px !important;
  left: initial !important;
}

.slick-next {
  background-image: url("../images/icons/right-arrow-black.svg") !important;
  right: 30px !important;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: .5;
}

.slick-track {
  margin: initial !important;
}

/*------------------------------------------------------------------

12. Other pages

-------------------------------------------------------------------*/
.e-terms-wrap h6 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.e-no-result-wrap {
  min-height: 50vh;
  padding-top: 60px !important;
}

.e-no-result-wrap h6 {
  padding: 30px 0 0;
  font-weight: 700;
  font-size: 18px;
}

.e-no-data-wrap {
  padding: 150px 0;
}

.e-terms-wrap {
  min-height: 100vh;
}



@media not all and (min-resolution:.001dpcm) {
  @media {
    header .e-search::-webkit-input-placeholder {
      line-height: 230% !important;
    }

    .e-p-btn,
    .e-enroll-btn {
      background-size: 202% 100%;
    }

  }
}

/*Category Dropdown*/
.e-mega-header {
  /*position: unset !important;*/
}

.e-mega-header .dropdown-menu.show {
  transform: translate3d(0px, 34px, 0px);
  top: 12px;
  min-width: 25vw;
  box-shadow: 0 0 10px #00000011;
  position: absolute;
  left: 0px;
  will-change: transform;
}

.e-mega-header .dropdown-menu {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #606060;
}

.e-mega-header .e-dropdown-btn {
  min-width: 100px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /*text-transform: uppercase;*/
  color: #000000;
  border: none;
  background-color: #00000000;
}

.e-mega-header .e-dropdown-btn:focus {
  box-shadow: none;
}

.e-mega-header span.e-header-btn {
  /*width: 100%;*/
  color: #000;
  font-weight: 500;
  background-color: #ffffff;
  background-image: url('../images/icons/arrow-black.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 98%;
  /*transition: 0.3s ease;*/
  cursor: pointer;
  line-height: 25px;
  font-size: 13px;
}

.e-product-height {
  line-height: 31px;
  font-size: 13px;
  font-weight: 500;
}

.e-mega-header span.e-header-btn.e-btn-on {
  /*background-color: #EDEDED;*/
  background-image: url('../images/icons/arrow-blue.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 98%;
  color: #065CAB;
  cursor: pointer;
  font-size: 13px;
}

.e-product-height.e-active-color:hover {
  color: #065CAB;
}

.dropdown-toggle::after {
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-left: none !important;
}

/*Webinar*/

.e-banner-section.e-webinar-banner {
  background: linear-gradient(90deg, #065CAB -0.66%, #46C212 100%), #065CAB;
  padding: 180px 0 100px;
}

.e-banner-section.e-webinar-banner .e-banner-pattern-1 {
  top: 126px;
}

.e-live-webinar-card .e-course-cover {
  height: 225px !important;
}

.e-latest-webinar .e-course-cover {
  height: 328px !important;
  border-radius: 0px 10px 10px 0px;
}

.e-course-header {
  /*background: #FF0303;*/
  /* border-radius: 10px;*/
  display: inline-block;
  position: absolute;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  padding: 3px 0px;
  left: 15px;
  top: 15px;
  right: 0;
  z-index: 1;
}

.e-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.e-webinar-video {
  height: 400px;
}

.e-vidoelist-play-btn {
  position: absolute;
  z-index: 666;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 0;
}

.e-webinar-video-cover {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
}

.e-course-live {
  background: #FF0303;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  color: #fff;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  padding: 3px 10px;
  right: 15px;
  bottom: 18px;
  z-index: 1;
}

.e-course-save {
  display: inline-block;
  position: absolute;
  right: 18px;
  bottom: 18px;
  z-index: 1;
}

.e-course-presented {
  display: inline-block;
  position: absolute;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
  bottom: 40px;
  left: 13px;
  z-index: 1;
}

.e-course-author {
  z-index: 1;
}

.e-course-by {
  display: inline-block;
  position: absolute;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 11px;
  line-height: 10px;
  bottom: 20px;
  left: 13px;
  z-index: 1;
}

.e-upcoming-webinar-card .e-course-author {
  border-radius: 0px 10px 0px 0px;
}

.e-save-btn {
  background-color: inherit;
  border: none;
}

.e-overlay:after,
.e-live-overlay:after {
  content: '';
  position: absolute;
  left: 0px;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: inline-block;
}

.e-overlay:after {
  background: linear-gradient(90deg, #000C40 0%, #607D8B 100%);
  opacity: 0.8;
  border-radius: 0px 10px 10px 0px;
}

.e-live-overlay:after {
  background: linear-gradient(90deg, #000000 0%, #262626 100%);
  opacity: 0.5;
  border-radius: 5px 5px 0 0;
}

.e-play-btn1 {
  width: 32px !important;
  height: 36px;
  position: absolute;
  top: 149px;
  right: 100px;
  z-index: 1;
  /*opacity: 0.5;*/
}

.card {
  border-radius: 1.25rem;
}

/*Webinar listing*/
.e-all-course-wrap.e-all-webinar-wrap .e-banner-section {
  background: linear-gradient(90deg, #065CAB -0.66%, #46C212 100%);
  padding: 150px 0 57px !important;
}

.e-all-course-wrap.e-all-webinar-wrap h3 {
  font-weight: 700 !important;
  top: 35px;
}

span.e-tabs-item.e-active {
  font-weight: 700;
  color: #000 !important;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  position: relative;
}

span.e-tabs-item.e-active::after {
  content: '';
  display: inline-block;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  top: 34px;
  height: 3px;
  background: #46C212;
  transition: width .3s;
  z-index: 1;
}

span.e-color-inactive {
  color: #000000;
  opacity: 0.3;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  position: relative;

}

.e-all-webinar-wrap .e-s-btn {
  padding: 12px 18px !important;
  font-size: 12px !important;
}

.e-webinar-filter .css-yk16xz-control {
  border-radius: 8px;
}


.e-webinar-filter .css-g1d714-ValueContainer,
.e-webinar-filter .css-1wa3eu0-placeholder {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

/* .e-webinar-filter .css-g1d714-ValueContainer {
  padding: 14px 8px;
} */

.e-webinar-filter .css-6q0nyr-Svg {
  stroke-width: 0px;
  color: #000;
}

.e-all-webinar-wrap h6 {
  font-size: 12px;
}

.e-webinar-filter label {
  font-weight: 700;
}

.e-webinar-filter .css-1okebmr-indicatorSeparator {
  width: 0px;
}

/*webinar detail*/
.e-webinar-banner-wrap,
.e-wallet-transactions {
  padding-bottom: 300px;
}

.e-open-account-banner-wrap {
  background: linear-gradient(90deg, #7474BF 0%, #348AC7 100%);
}

.e-webinar-detail-wrap .e-app-store-banner-wrap {
  padding: 15px !important;
}

.e-acct-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.e-app-store-banner-wrap.e-open-account-banner-wrap {
  height: 182px;
}

.e-app-store-banner-wrap.e-open-account-banner-wrap h5,
.e-app-store-banner-wrap h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 35px !important;

}

.e-webinar-detail-wrap button {
  background: #46C212;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  padding: 7px 10px;
}

p.e-logo {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  /*opacity: 0.8;*/
}

p.e-logo span {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.e-video-detail-wraper {
  background: #FFFFFF;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
}

.e-video-detail-wraper h4 {
  font-weight: 700;
  font-size: 24px;
}

.e-video-detail-wraper p.e-date-time span {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  opacity: 0.47;
}

.e-tips-span {
  background: #09491B;
  border-radius: 24px;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  padding: 5px 13px;

}

p.e-views {
  font-weight: 600;
  font-size: 18px;
}

.e-creator-logo {
  height: 40px;
  border-radius: 49%;
}

.e-views-count {
  font-weight: 800;
}

.e-webinar-dtl-fav {
  width: 50px;
}

.e-web-fav {
  width: 46px;
}

.e-webinar-dtl-fav:hover,
.e-web-fav:hover {
  cursor: pointer;
}

/*Wallet history*/
.e-banner-section.e-wallet-history-banner {
  background: #065CAB;
  padding: 134px 0 35px !important;
}

.e-rupees {
  font-family: 'Roboto';
}

.e-banner-section.e-wallet-history-banner .container,
.e-wallet-transactions .container {
  padding: 0px 200px;
}

.e-banner-section.e-wallet-history-banner h6 {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.e-banner-section.e-wallet-history-banner h3 {
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
}

.e-wallet-transactions h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: rgba(68, 68, 68, 0.67);
}

.e-transaction-border {
  border-bottom: 2px solid #EEEEEE;
}

.e-transaction-date {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #444444;
}

.e-transaction-desc {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

.e-debit-amount,
.e-credit-amount {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #13D726;
  text-align: right;
}

.e-credit-amount {
  color: #444444 !important;
}

.e-receive {
  color: #00ff00;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
}

/*Loaders*/
.e-page-overlay-loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(255 255 255 / 0.8);
  left: 0;
  top: 0;
  z-index: 99;
  text-align: center;
  padding-top: 250px;
}

.e-page-loader {
  width: 300px;
  background-color: inherit;
}

/*Toast*/
.e-error {
  background-color: #F5C0C0;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 13px;
  color: #D40000;
  font-weight: 500;
  border: 1px solid #D40000;
  text-transform: capitalize;
}

.e-toast {
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 250px;
  padding: 15px 15px 15px 40px;
  background-color: #fff;
  border: 1px solid #F1F1F1;
  border-radius: 5px;
  box-shadow: 3px 4px 6px 1px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  background-size: 18px;
  background-position: 15px;
  background-repeat: no-repeat;
  z-index: 99;
}

.e-toast.success {
  background-image: url('../images/icons/check.svg');
  border-color: #68D868;
  background-color: #E9FFE0;
}

.e-toast.fail {
  background-image: url('../images/icons/cancel.svg');
  border-color: red;
  background-color: #F9E5E5;
}

/*------------------------------------------------------------------

8. Refer Card

-------------------------------------------------------------------*/
.e-refer-card {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 2px 24px 0px rgba(76, 80, 94, 0.12);
  /* padding: 40px 30px; */
}

.e-refer-card .e-form-rule {
  top: 12px;
  left: 30px;
}

.e-refer-card .e-text {
  border-radius: 4px 0px 0px 4px;
  /* padding-right: 40px; */
  border-right: none;
  width: 100%;
  /* padding-right: 100px; */
}

.e-refer-input-wrap button {
  /* position: absolute;
  right: -18px;
  top: 0;
  padding: 15px 20px !important; */
  cursor: pointer;

  position: absolute;
  right: -5px;
  top: 0;
  padding: 6px 12px !important;
}

/*.e-ts-refer{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  border-radius: 0px 4px 4px 0px;
  padding: 10px 20px;
  width: 130px;
}
.e-ts-refer:hover{
  background-color: #2756A2;
}*/
/* .e-refer-card .e-p-btn {
  width: 152px;
} */

.e-refer-card .e-p-btn[name="Clear"] {
  background-color: red;
}

.e-refer-card .e-p-btn[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.e-refer-card .e-p-btn[disabled]:hover {
  background: #0461D1;
}

.e-generated-head {
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #444444
}

.e-prdtdtls-smallcase-h5 {
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  color: #444444;
}

.e-earn-p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: 'ss03' on, 'ss01' on;
  color: #424242;
  /* padding-right: 10px; */
}

.e-ftricon-link {
  text-decoration: none;
  color: #444444;
  font-size: 16px;
}

.e-copy-btn {
  /*font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'ss01' on, 'ss03' on;
  color: #0461D1;
  padding: 8px;
  text-align: center;
  border: 1px solid #0461D1;
  border-radius: 4px;
  width: 80px;*/
  background-color: white;
  border: none;

}

.e-earn-link {
  color: #0461D1 !important;
}

.e-copy-btn:focus {
  border: 1px solid #0461D1 !important;
}

.e-link-copied {
  display: block;
  margin-top: 10px;
  background: #d4efde;
  padding: 5px 15px 4px;
  border: 1px solid #178617;
  border-radius: 8px;
  font-weight: 500;
  color: #178617;
  text-align: center;
  font-size: 13px;
  z-index: 1;
}

.e-link-copied-s {
  display: inline-block;
  width: 150px;
}


/*notification pop up*/
.e-notification-popup {
  background-image: url('../images/Frame232.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 136%;
  width: 445px;
  /*height: 178px;*/
  position: fixed !important;
  bottom: 0;
  right: 0;
  z-index: 999;
  box-shadow: 8px 16px 32px rgba(185, 185, 185, 0.54);
}

.e-popup-close {
  padding: 2px 9px;
  position: absolute;
  right: 21px;
  top: -12px;
  border-radius: 100%;
  box-shadow: 8px 16px 32px rgba(185, 185, 185, 0.54);
  border: none;
}

.e-popup-close .e-toggle {
  width: 11px !important;
}

.e-popup-img {
  width: 100%;
}

.e-notification-popup h5 {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #000000;
}

.e-notification-popup p {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #444444
}

.e-popup-link {
  background: #13D726;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  transition: ease-in-out 0.5s;
}

.e-popup-link:hover {
  color: #FFFFFF;
  text-decoration: none;
  transform: translateY(-3px);
}

/*------------404--------*/
.e-page-not-found img {
  padding: 125px 0 0;
}

.e-page-not-found h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #444444;
}

.e-page-not-found p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #444444;
  padding-bottom: 100px;
}


/*home slider*/
.e-slider-item {
  padding: 15px 30px 15px 0;
}

.e-home-slider .slick-arrow {
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 5.88%, rgba(255, 255, 255, 0) 136.84%);
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  backdrop-filter: blur(80px);
  border-radius: 23px; */
}

.e-home-slider .slick-prev,
.e-home-slider .slick-next {
  background-image: url("../images/icons/arrow-prev.svg") !important;
  background-size: 13px !important;
  width: 13.5px !important;
  height: 11.25px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: absolute !important;
  top: 89% !important;
  left: 14% !important;
  z-index: 9;
}

.e-home-slider .slick-next {
  background-image: url("../images/icons/arrow-next.svg") !important;
  left: 16% !important;

}

.e-home-slider .slick-prev:before,
.e-home-slider .slick-next:before {
  display: none;
}

.e-home-slider .slick-prev.slick-disabled,
.e-home-slider .slick-next.slick-disabled {
  opacity: .5;
}

.e-feature-slider .slick-prev,
.e-feature-slider .slick-next {
  background-image: url("../images/home/arrow2.svg") !important;
  background-repeat: no-repeat !important;
  z-index: 9;
  top: -38px !important;
  padding: 12px;
}

.e-feature-slider .slick-next {
  background-image: url("../images/home/arrow1.svg") !important;
  /* left: 16% !important; */

}

.e-feature-slider .slick-prev:before,
.e-feature-slider .slick-next:before {
  display: none;
}

.e-feature-slider .slick-slide {
  width: max-content !important;
}

.e-home-slider .slick-prev.slick-disabled,
.e-home-slider .slick-next.slick-disabled {
  opacity: .5;
}

/*feature slider*/
.e-feature-slider span.e-btn {
  background: #F7F7F7;
  border-radius: 170px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  padding: 10px 24px !important;
  height: 38px;
  width: max-content;
}

.e-feature-slider span.e-active-btn {
  background: #065CAB;
  border-radius: 170px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  padding: 10px 24px !important;
  height: 38px;
  width: max-content;
}

.e-feature-slider .slick-list {
  padding: 10px 0 10px 0 !important
}

/*-------offer modal-----*/
.e-offer-modal .modal-dialog {
  max-width: 542px !important;
}

.e-offer-modal .modal-header .close {
  right: 11px;
  position: absolute;
  padding: 0 !important;
  margin: 0 !important;
  top: 12px;
}

.e-offer-modal .close {
  opacity: 10 !important;
}

.e-offer-modal h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
}

.e-offer-modal p {
  font-weight: 500;
}

/*-------Profile changes------*/
.e-profile-wraper {
  background: #FFFFFF;
  border-radius: 16px;
  padding: 12px 30px 24px;
  /* height: 228px; */
}

.e-profile-icon-span {
  background: linear-gradient(90deg, #46C212 -0.66%, #065CAB 100%), #065CAB;
  border: 2px solid #fff;
  box-sizing: border-box;
  display: inline-block;
  padding: 20px;
  border-radius: 100%;
  letter-spacing: .01em;
  color: #ffffff;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
}

.e-profile-wraper h5 {
  color: #000000;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
}

.e-profile-detail-wrap {
  background: rgba(183, 178, 178, 0.2);
  border-radius: 16px;
  padding: 20px 18px;
}

.e-profile-detail-wrap p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.e-profile-detail-wrap p span {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}

.e-balnce {
  font-size: 18px !important;
  padding: 12px 0;
}

/*-------Webinar detail-------*/
.e-webinar-detail-container .container {
  padding-top: 120px;
}

.e-webinar-detail-container .e-webinar-link {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #000000;
}

.e-webinar-detail-container .e-webinar-link:hover {
  text-decoration: underline !important;
}

.e-upcoming {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #000000;
}

a.e-webinar-name {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #8B8B8B !important;
}

.e-webinarDetails-h6 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

/* .e-nav-wrap {
  border: 3px solid #46C212;
  border-radius: 8px;
  width: 100%;
  height: 130px;
} */
.e-webinar-about h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.e-webinar-about ul {
  padding-left: 26px;
}

.e-webinar-about ul li,
.e-webinar-about p {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

/*faq*/
.e-collapse-sec h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;

}

.e-collapse-sec {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(196, 191, 191, 0.25);
  border-radius: 4px;
  padding: 10px 16px;
}

.e-faq-minus {
  transform: rotate(180deg);
}

.e-write-review-btn {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 13.5px !important;
  text-align: center;
}

.e-review-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(89, 83, 83, 0.08);
  border-radius: 8px;
  padding: 8px 12px;
}

.e-review-card h6 {
  font-weight: 700;
  font-size: 14px;
}

.e-review-card p {
  font-size: 12px;
}

.e-review-content {
  font-size: 14px !important;
  line-height: 18px !important;
}

.e-review-card .e-reviewer {
  object-fit: contain;
}

.e-webinar-details-wrapper,
.e-refer-earn-wrap-webinar {
  background: #FFFFFF;
  box-shadow: 0px 2px 24px rgba(76, 80, 94, 0.12);
  border-radius: 8px;
  padding: 16px 16px 0 16px;
}

.e-webinar-details-wrapper h5 {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  line-height: 0;
}

.e-refer-earn-wrap-webinar h5 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin: 0 !important;
}

.e-share {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #065CAB;
  cursor: pointer;
  padding-top: 2px;
}

.e-host-wrap {
  background: rgba(183, 178, 178, 0.2);
  border-radius: 4px;
  padding: 12px;
}

.e-host-detail {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.67);
}

.e-host-detail span {
  font-weight: 500 !important;
  color: #000000;
}
.e-host-detail p {
  font-weight: 500 !important;
  color: #000000;
}


span.e-webinar-type {
  font-weight: 800 !important;
  font-size: 14px;
  line-height: 18px;
  color: #FFA621 !important;
}

.e-enroll-wrap {
  background: #E5F2FF;
  border-radius: 4px;
  padding: 16px 16px;
}

.e-enroll-wrap p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.e-enroll-wrap span {
  font-weight: 600;
  font-size: 18px;
}

.e-note {
  font-size: 10px !important;
}

.e-available-wrap {
  background: linear-gradient(163.42deg, #46C212 -2.84%, #2F7013 90.34%);
  border-radius: 8px;
  padding: 18px 16px;
  width: 350px;
  height: 466px;
}

.e-available-wrap h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.e-available-wrap h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}

.e-mask-img {
  position: absolute;
  bottom: 0;
  top: 72px;
}

.e-refer-earn-wrap-webinar p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  opacity: 0.67;
}

.e-refer-earn-wrap-webinar .e-enroll-wrap span {
  font-size: 12px;
}

.e-webinar-id-refer {
  padding: 7px 8px !important;
  /* border: 1px solid transparent !important; */
}

.e-webinar-id-refer::placeholder {
  font-size: 13px !important;
}

.e-id-arrow {
  background: #065CAB;
  border-radius: 4px;
  padding: 8px 24px;
  cursor: pointer;
}

.e-id-arrow:hover img {
  transform: translateX(10px);
  transition: all .3s ease-in-out;

}

.e-position-wraper {
  position: absolute;
  right: 100px;
  top: 60px;
}

.e-cvr-height {
  height: 640px !important;
}

.e-pt-container {
  padding-top: 250px !important;
}

.e-live-data h5 {
  line-height: 34px !important;
  font-size: 28px !important;
}

.e-enroll-wrap.e-live-view p {
  font-weight: 600;
  font-size: 14px;
}

.e-enroll-wrap.e-live-view h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #065CAB;
}

.e-live-img {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #F52424;
}

/* ----------------------------------------------- */


.e-tab-items {
  overflow: auto;
  white-space: nowrap;

}

::-webkit-scrollbar {
  display: none;
}

.react-date-picker__wrapper {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  padding: 5px 6px;
  border-radius: 5px;
  border: 1px solid #ccc !important;
  cursor: pointer;

}

.e-calender-input {
  margin-right: 10px;
}

.e-calender-img {
  height: 16px;
  width: 16px;
  margin-left: 20px;
}

.e-webinar-search {
  width: 236px;
  border-radius: 4px;
  background: #F1F1F1;
  float: right;
  /* background-image: url('../images/icons/search.svg');
  background-repeat: no-repeat;
  background-position: 92%; */

  margin-right: 46px;
  color: #000000;
  font-size: 13px;
  font-weight: 500;
  padding: 14px 40px 14px 14px !important;
}

.e-web-search-submit {
  height: 47px;
}

.react-date-picker__inputGroup__input {
  background: transparent !important;

}

.react-date-picker__inputGroup__input:focus {
  border: none !important;
}

/* .react-date-picker__inputGroup__day {
  width: 21px!important;
}
.react-date-picker__inputGroup__month{
  width: 23px!important;
}
.react-date-picker__inputGroup__year{
  width: 34px!important;
} */

/* .react-date-picker__inputGroup__input--hasLeadingZero{
  margin-left: 0 !important;
  padding-left: 0 !important;
} */
/* .css-tlfecz-indicatorContainer {
  background: url('../images/dropdown.png') no-repeat;
  background-size: 10px;
  background-position: 80%;
} */

.react-calendar {
  font-family: 'Quicksand' !important;
  margin-top: 6px;
  /* border: 1px solid #ccc !important; */
  border: none !important;
  width: 296px !important;
  padding: 8px;
  border-radius: 4px;
  background: #FFF !important;
  box-shadow: 0px 4px 18px 0px rgba(78, 73, 73, 0.08) !important;
}

.react-date-picker__calendar.react-date-picker__calendar--open {
  z-index: 9 !important;
}

.react-calendar__tile--now {
  background: transparent !important;
}

.react-calendar__tile--now:hover {
  background: #3547E9 !important;
}

.react-calendar__month-view__days__day:hover {
  background: #3547E9 !important;
  color: #FFF !important;
}

.e-clear-button {
  height: 10px;
  width: 10px;

}

.e-calender-close {
  background: transparent;
  left: 96px;
  top: 8px;
}

.e-play-btn2 {
  width: 32px !important;
  height: 36px;
  position: absolute;
  top: 149px;
  right: 40%;
  z-index: 1;
}

.e-banner-section.e-webinar-banner-section {
  height: unset !important;
}

.webinar-detail-img {
  width: 100%;
  height: 400px;
  border-radius: 8px;
}

.e-banner-privacy,
.e-result-banner,
.e-terms-banner,
.e-allcourse-banner,
.e-profile-banner,
.e-tutor-banner {
  height: unset !important;
}

.react-calendar__tile--active:enabled {
  background: #3547E9 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #fff !important;

}

.react-calendar__tile {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #000 !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #747474 !important;
}

.react-calendar__month-view__weekdays {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding-bottom: 4px;
}

.react-calendar__month-view__weekdays abbr {
  text-decoration: none !important;
}

.react-calendar__navigation button {
  font-size: 18px;
  font-weight: 700;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

/* .react-calendar__viewContainer{
  padding-top: 10px;
} */
.react-calendar__year-view__months__month {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.react-calendar__year-view__months__month:hover {
  background: #3547E9 !important;
  color: #FFF !important;
}

.react-calendar__tile--hasActive {
  background: #3547E9 !important;
  color: #FFF !important;
  font-weight: 600 !important;
}

.react-calendar__decade-view__years__year:active {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.react-calendar__decade-view__years__year:hover {
  background: #3547E9 !important;
  color: #FFF !important;
}

.react-calendar__century-view__decades__decade:hover {
  background: #3547E9 !important;
  color: #FFF !important;
}

.e-bookmark-img {
  height: 24px;
  margin-top: 8px;
  cursor: pointer;

}

.e-enrollnow-btn {
  width: 100%;
}

.e-bookmark {
  padding-left: 10px;
}

.e-category {
  color: #065CAB !important;
  font-size: 12px !important;
}

.e-disable-input {
  pointer-events: none;
  opacity: .5;

}

/* .e-detail-category {
  padding-left: 6px;
} */

.e-detail-category:last-child {
  padding-left: 0 !important;
}

.upcoming-search-close {
  width: 12px !important;
  position: absolute;
  right: 72px;
  top: 17px;
  opacity: .5;
  cursor: pointer;
}

.upcoming-search-icon {
  position: absolute;
  right: 72px;
  top: 17px;
}

.e-social-icon {
  /* gap: 0 30px; */
  background: #E5F2FF;
  border-radius: 4px;
  padding: 16px 16px;
  justify-content: space-around;
}

.e-social-icon a {
  cursor: pointer;
}

.e-social-icon-img {
  height: 20px;
  width: 20px;
}

.e-social-icon img:hover {
  transform: scale(1.1);
  transition: all .3s ease-in-out;
}

.e-detail-thumbnail {
  border-radius: 8px;

}

.e-detail-thumbnail img {
  height: 130px;
  width: 190px;
  border-radius: 8px;
  cursor: pointer;
}

.webinar-detail-play {
  position: absolute;
  right: 46%;
  bottom: 168px;
  cursor: pointer;
}

.e-youtube-modal-close {
  border: none;
  position: absolute;
  right: 20px;
  top: 20px;
  height: 40px;
  background: #fff;
  width: 40px;
}

.e-youtube-modal-close img {
  /* height: 12px; */
  width: 16px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 26px !important;
  width: 26px !important;
}

.carousel-control-next {
  right: -16px;
  bottom: 170px;
}

.carousel-control-prev {
  left: -16px;
  bottom: 170px;
}

.e-course-for {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: rgba(0, 0, 0, 0.67) !important;
}

.e-modal-dialog {
  max-width: 700px !important;
}

.e-ref-and-earn {
  padding: 16px 46px 0px 16px;

}

.e-disable {
  /* opacity: .5; */
  cursor: not-allowed;
}

.e-disable:hover {
  background: #065CAB !important;
  border: 1px solid #065CAB;
}

.e-generate-link {
  border-radius: 0px 0px 8px 8px;
  background: #E5F2FF;
  padding: 16px;
}

.e-generate-link input {
  border-radius: 4px !important;
  background: #FFF;
  color: #AEAEAE;
  font-family: 'Quicksand';
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.13px;
  padding: 8px !important;
}

.e-generate-link h4 {
  color: #000;
  font-family: 'Quicksand';
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}

.e-select-box .css-1pahdxg-control {
  padding: 5px !important;
  border-radius: 4px !important;
}

.e-saved {
  font-size: 16px;
}

.e-edit-select .css-1n7v3ny-option {
  font-size: inherit !important;
}

.e-select-box .css-1n7v3ny-option {
  font-size: inherit !important;
}

.e-disclaimer-numbers span {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 16px;
  padding-right: 4px;
}

.e-disclaimer-numbers p {
  font-weight: 400;
  line-height: 1.5;
}

.e-disclaimer-link p {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 16px;

}

.e-disclaimer-link a {
  color: #007bff;
  text-decoration: none;
  font-weight: 400;
  padding-left: 4px;
}

.e-disclaimer-link a:hover {
  color: #0056b3;
  text-decoration: underline !important;
}

.e-footer-two {
  background: #161616;
  padding: 40px 0;
}

.e-footer-two p {
  color: #F0F0F0;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.6700000166893005;
  margin-bottom: 12px;
  padding-inline-end: 22px;
}

.e-footer-two a {
  color: #0461d1 !important;
  margin-right: 16px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.e-footer-two a:hover {
  text-decoration: underline !important;
}

.e-footer-two span {
  color: #13D726 !important;
  font-weight: 800 !important;
  margin-right: 26px;
  font-size: 12px;
}

.e-footer-two img {
  width: 14px;
  height: 16px;
  margin-right: 8px;
}


.e-footer-three {
  background: #1D1D1D;
  padding: 40px 0;
}

.e-footer-three p {
  color: #F0F0F0;
  font-size: 12px;
  font-weight: 200;
}

.e-footer-three span {
  color: #0461D1 !important;
}

.e-footer-three a {
  color: #0461D1 !important;
}

.e-footer-three .e-font-weight-normal {
  font-weight: 400;
  color: #FFF !important;
}

.e-aliceblue-logo img {
  width: 90px;
  padding-left: 20px;
}

.e-aliceblue-logo span {
  color: #000;
  font-weight: 500;
  font-size: 10px;
  /* line-height: 14px; */
  letter-spacing: 0.01em;
  text-decoration-line: none;
  cursor: pointer;
  left: 0;
  bottom: 10px;
}

.e-aliceblue-logo-mob img {
  width: 90px;
  padding-left: 12px;
}

.e-aliceblue-logo-mob span {
  top: 20px;
  color: #000;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.01em;
  text-decoration-line: none;
  cursor: pointer;
}

.e-language-chip {
  padding: 2px 8px;
  border-radius: 12px;
  /* border: 1px solid #0461D1; */
  background: #EBF4FF;
}

.e-flex-wrap {
  flex-wrap: wrap;
}

.e-display-inline {
  display: inline-flex;
}

.e-parnerid_wrapper{
  height: 86px;
}