@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    "Tradeschool - Course listing platform for trading domain"
Version:    1.0
Created:    "28 May 2020 - 12.00 P"
Use:   "Webinar & course listing platform"
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Table of contents]

1. Fonts import, Typography
2. Colors
3. Page loader / .page-loader
4. Global styles
5. Buttons and CTA
6. Forms fields
7. Animations
7. Other


-------------------------------------------------------------------*/

/*------------------------------------------------------------------

1. Fonts import, Typography

-------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:700&display=swap');

body,
html {
  font-family: 'Quicksand', sans-serif;
  font-size: 15px;
  font-weight: 300;
  overflow-x: hidden !important;
}

h1 {
  font-size: 64px;
  line-height: 80px;
  letter-spacing: 0.01em;
  font-weight: 500;
  position: relative;
  z-index: 1;
}

h2 {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
  font-weight: 300;
}

h3 {
  font-size: 28px;
  line-height: 35px;
  font-weight: 500;
}

h4 {
  color: #000000;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
}

h5 {
  color: #fff;
  letter-spacing: 0.01em;
  font-size: 48px;
  line-height: 60px;
  font-weight: 300;
}

/*------------------------------------------------------------------

2. Colors

-------------------------------------------------------------------*/
.e-grey-bg {
  background: #F7F8FC;
}

.e-blue-bg {
  background: #E0F0FF;
}

.e-green-bg {
  background: #D7FFC7;
}

/*------------------------------------------------------------------

3. Page loader / .page-loader

-------------------------------------------------------------------*/




/*------------------------------------------------------------------

4. Global styles

-------------------------------------------------------------------*/
*,
*:focus {
  outline: none !important;
  box-shadow: none;
}

.e-section {
  padding: 100px 0 80px;
}

.e-p-ellipsis-1 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 20px;
  line-height: 18px;
}

.e-p-ellipsis-2 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 40px;
  line-height: 18px;
}

.e-min-height-50 {
  height: 50vh;
}


/*------------------------------------------------------------------

5. Buttons and CTA

-------------------------------------------------------------------*/
.e-p-btn {
  background: #065CAB;
  border-radius: 4px;
  padding: 18px 28px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #065CAB;

  cursor: pointer;
  background-image: linear-gradient(to left, transparent, transparent 50%, #000 50%, #000);
  background-position: 100% 0;
  background-size: 201% 100%;
  transition: all .25s ease-in;
}

.e-p-btn.e-inactive {
  opacity: .7;
  cursor: not-allowed;
}

.e-p-btn:hover {
  background-position: 0 0;
  color: #fff;
  border: 1px solid #000;
}

.e-p-btn.e-inactive:hover {
  background-position: 100% 0;
  border: 1px solid #065CAB;
}

.e-s-btn {
  color: #065CAB;
  letter-spacing: 0.01em;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border: 1px solid #065CAB;
  border-radius: 5px;
  padding: 18px 28px;
  display: inline-block;

  cursor: pointer;
  background-image: linear-gradient(to left, transparent, transparent 50%, #000 50%, #000);
  background-position: 100% 0;
  background-size: 201% 100%;
  transition: all .35s ease-in;
}

.e-s-btn:hover {
  background-position: 0 0;
  color: #fff;
  border: 1px solid #000;
}

.e-link-txt-header {
  color: #000;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-decoration-line: none;
  display: inline-block;
  cursor: pointer;
  transition: all .25s ease-in;
}

.e-link-txt-header.e-active::after {
  content: '';
  display: block;
  width: 100%;
  position: relative;
  bottom: 0px;
  top: 5px;
  height: 2px;
  background: #46C212;
  transition: width .3s;
  z-index: 1;
}

.e-link-txt-green:hover {
  color: #065CAB;
}

.e-view-all {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  font-weight: 600;
  padding-right: 60px;
  background-image: url("../images/icons/right-arrow-black.svg");
  background-repeat: no-repeat;
  background-position: 90%;
  display: inline-block;
  color: #000000;
  transition: all .2s ease-in;
}

.e-view-all:hover {
  background-position: 100%;
  background-image: url("../images/icons/right-arrow-blue.svg");
}

.e-view-all.text-white {
  background-image: url("../images/icons/right-arrow-white.svg");
  font-weight: 500;
}

.e-view-all.text-white:hover {
  background-position: 100%;
  background-image: url("../images/icons/right-arrow-white.svg");
}

.e-link-txt-blue {
  color: #065CAB;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.e-link-txt-blue:hover {
  color: #000;
}

.e-link-txt-blue.e-mail-sent {
  color: green;
}

.e-pagination-loader {
  width: 100px;
  height: 20px;
  background-image: url("../images/loader/lazyload.gif");
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

/*------------------------------------------------------------------

6. Forms fields

-------------------------------------------------------------------*/

input,
textarea {
  box-shadow: none;
  border: none;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 13px;
  font-size: 16px;
  border-radius: 5px;

  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.e-input-wrap {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

input~label,
textarea~label {
  position: absolute;
  left: 10px;
  top: 10px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #818E9B;
  background: #fff;
  padding: 5px;

  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

input:focus,
textarea:focus {
  border: 1px solid #065CAB;
}

input:focus~label,
input:valid~label {
  color: #000000;
  top: -14px;
}

textarea:active~label,
textarea:focus~label,
textarea.e-active~label {
  top: -8px;
}

input [type=file],
#e-logo-upload {
  width: 1px;
  height: 1px;
  opacity: 0;
  padding: 0;
  margin: 0;
  position: absolute;
}

[type="radio"]~label {
  left: 0;
  top: 0 !important;
  padding: 0;
  margin-bottom: 0;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 18px;
  display: inline-block;
  color: #000000;
  margin-right: 30px;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #000000;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:before {
  border: 1px solid #46C212;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #46C212;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}






/* The container */
.e-checkbox-wrap {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;


  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 16px;


  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.e-checkbox-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #000;
}

/* On mouse-over, add a grey background color */
.e-checkbox-wrap:hover input~.checkmark {
  background-color: #fff;
  border: 1px solid #ccc;
}

/* When the checkbox is checked, add a blue background */
.e-checkbox-wrap input:checked~.checkmark {
  background-color: #46C212;
  border: 1px solid #46C212;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.e-checkbox-wrap input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.e-checkbox-wrap .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


/*------------------------------------------------------------------

7. Animations

-------------------------------------------------------------------*/
.e-card-transition {
  cursor: pointer;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.e-card-transition:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.2);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 60px, 0);
    transform: translate3d(0, 60px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}



/*------------------------------------------------------------------

8. Other

-------------------------------------------------------------------*/


.fontQuicksand {
  font-family: 'Quicksand', sans-serif;
}

.css-9gakcf-option {
  font-weight: 500;
  font-size: 12px !important;
  background-color: #065CAB !important;
}

.css-1n7v3ny-option {
  font-weight: 300 !important;
  font-size: 15px !important;
}

.css-1uccc91-singleValue {
  line-height: 1 !important;
}

/* .react-calendar__tile--active{
  background: #065CAB!important;
} */
@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    "Tradeschool - Course listing platform for trading domain"
Version:    1.0
Created:    "28 May 2020 - 12.00 P"
Use:   "Webinar & course listing platform"
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Table of contents]

1. Fonts import, Typography
2. Colors
3. Page loader / .page-loader
4. Global styles
5. Buttons and CTA
6. Forms fields
7. Animations
7. Other


-------------------------------------------------------------------*/

/*------------------------------------------------------------------

1. Fonts import, Typography

-------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:700&display=swap');

body,
html {
  font-family: 'Quicksand', sans-serif;
  font-size: 15px;
  font-weight: 300;
  overflow-x: hidden !important;
}

h1 {
  font-size: 64px;
  line-height: 80px;
  letter-spacing: 0.01em;
  font-weight: 300;
  position: relative;
  z-index: 1;
}

h2 {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
  font-weight: 300;
}

h3 {
  font-size: 28px;
  line-height: 35px;
  font-weight: 500;
}

h4 {
  color: #000000;
  font-weight: 600;
  font-size: 28px;
  line-height: 35px;
}

h5 {
  color: #fff;
  letter-spacing: 0.01em;
  font-size: 48px;
  line-height: 60px;
  font-weight: 300;
}

/*------------------------------------------------------------------

2. Colors

-------------------------------------------------------------------*/
.e-grey-bg {
  background: #F7F8FC;
}

.e-blue-bg {
  background: #E0F0FF;
}

.e-green-bg {
  background: #D7FFC7;
}

/*------------------------------------------------------------------

3. Page loader / .page-loader

-------------------------------------------------------------------*/




/*------------------------------------------------------------------

4. Global styles

-------------------------------------------------------------------*/
*,
*:focus {
  outline: none !important;
  box-shadow: none;
}

.e-section {
  padding: 100px 0 80px;
}

.e-p-ellipsis-1 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 20px;
  line-height: 18px;
}

.e-p-ellipsis-2 {
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 40px;
  line-height: 18px;
}

.e-min-height-50 {
  height: 50vh;
}


/*------------------------------------------------------------------

5. Buttons and CTA

-------------------------------------------------------------------*/
.e-p-btn {
  background: #065CAB;
  border-radius: 4px;
  padding: 18px 28px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #065CAB;

  cursor: pointer;
  background-image: linear-gradient(to left, transparent, transparent 50%, #000 50%, #000);
  background-position: 100% 0;
  background-size: 201% 100%;
  transition: all .25s ease-in;
}

.e-p-btn.e-inactive {
  opacity: .7;
  cursor: not-allowed;
}

.e-p-btn:hover {
  background-position: 0 0;
  color: #fff;
  border: 1px solid #000;
}

.e-p-btn.e-inactive:hover {
  background-position: 100% 0;
  border: 1px solid #065CAB;
}

.e-s-btn {
  color: #065CAB;
  letter-spacing: 0.01em;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border: 1px solid #065CAB;
  border-radius: 5px;
  padding: 18px 28px;
  display: inline-block;

  cursor: pointer;
  background-image: linear-gradient(to left, transparent, transparent 50%, #000 50%, #000);
  background-position: 100% 0;
  background-size: 201% 100%;
  transition: all .35s ease-in;
}

.e-s-btn:hover {
  background-position: 0 0;
  color: #fff;
  border: 1px solid #000;
}

.e-link-txt-header {
  color: #000;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-decoration-line: none;
  display: inline-block;
  cursor: pointer;
  transition: all .25s ease-in;
}

.e-link-txt-header.e-active::after {
  content: '';
  display: block;
  width: 100%;
  position: relative;
  bottom: 0px;
  top: 5px;
  height: 2px;
  background: #46C212;
  transition: width .3s;
  z-index: 1;
}

.e-link-txt-green:hover {
  color: #065CAB;
}

.e-view-all {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  font-weight: 600;
  padding-right: 60px;
  background-image: url("../images/icons/right-arrow-black.svg");
  background-repeat: no-repeat;
  background-position: 90%;
  display: inline-block;
  color: #000000;
  transition: all .2s ease-in;
}

.e-view-all:hover {
  background-position: 100%;
  background-image: url("../images/icons/right-arrow-blue.svg");
}

.e-view-all.text-white {
  background-image: url("../images/icons/right-arrow-white.svg");
  font-weight: 500;
}

.e-view-all.text-white:hover {
  background-position: 100%;
  background-image: url("../images/icons/right-arrow-white.svg");
}

.e-link-txt-blue {
  color: #065CAB;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
}

.e-link-txt-blue:hover {
  color: #000;
}

.e-link-txt-blue.e-mail-sent {
  color: green;
}

.e-pagination-loader {
  width: 100px;
  height: 20px;
  background-image: url("../images/loader/lazyload.gif");
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

/*------------------------------------------------------------------

6. Forms fields

-------------------------------------------------------------------*/

input,
textarea {
  box-shadow: none;
  border: none;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 13px;
  font-size: 16px;
  border-radius: 5px;

  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.e-input-wrap {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

input~label,
textarea~label {
  position: absolute;
  left: 10px;
  top: 10px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: #818E9B;
  background: #fff;
  padding: 5px;

  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

input:focus,
textarea:focus {
  border: 1px solid #065CAB;
}

input:focus~label,
input:valid~label {
  color: #000000;
  top: -14px;
}

textarea:active~label,
textarea:focus~label,
textarea.e-active~label {
  top: -8px;
}

input [type=file],
#e-logo-upload {
  width: 1px;
  height: 1px;
  opacity: 0;
  padding: 0;
  margin: 0;
  position: absolute;
}

[type="radio"]~label {
  left: 0;
  top: 0 !important;
  padding: 0;
  margin-bottom: 0;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 18px;
  display: inline-block;
  color: #000000;
  margin-right: 30px;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #000000;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:before {
  border: 1px solid #46C212;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #46C212;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}






/* The container */
.e-checkbox-wrap {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;


  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 16px;


  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.e-checkbox-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #000;
}

/* On mouse-over, add a grey background color */
.e-checkbox-wrap:hover input~.checkmark {
  background-color: #fff;
  border: 1px solid #ccc;
}

/* When the checkbox is checked, add a blue background */
.e-checkbox-wrap input:checked~.checkmark {
  background-color: #46C212;
  border: 1px solid #46C212;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.e-checkbox-wrap input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.e-checkbox-wrap .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


/*------------------------------------------------------------------

7. Animations

-------------------------------------------------------------------*/
.e-card-transition {
  cursor: pointer;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.e-card-transition:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.2);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 60px, 0);
    transform: translate3d(0, 60px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(40px, 0, 0);
    transform: translate3d(40px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}



/*------------------------------------------------------------------

8. Other

-------------------------------------------------------------------*/




.fontQuicksand {
  font-family: 'Quicksand' !important;
}

.css-9gakcf-option {
  font-weight: 500;
  font-size: 14px !important;
  background-color: #065CAB !important;
}

.css-1n7v3ny-option {
  font-weight: 300 !important;
  font-size: 15px !important;
  cursor: pointer !important;
}

.css-1uccc91-singleValue {
  line-height: 1 !important;
}

.e-cursor-pointer {
  cursor: pointer;
}

.e-z-index-9 {
  z-index: 9;
}

.e-overfloqY-hidden {
  overflow-y: hidden;
}

.e-live-padding-top {
  padding-top: 120px;
}

.e-visibility-hidded {
  visibility: hidden;
}

.e-error-text {
  line-height: 1.2;
  color: #ff0000;
  font-size: 12px;
}

.height-44px {
  height: 44px;
}

.e-gap-18px {
  gap: 0 18px;
}

.h-360px {
  height: 360px;
}


/* type number input hide right side button */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.e-border-radius-8px{
  border-radius: 8px;
}

